<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <inbox-detail />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ViewSkeleton from '@/components/utils/view-skeleton';
import InboxDetail from '@/components/inbox/inbox-detail';
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    ViewSkeleton,
    InboxDetail,
  },
  computed: {
    ...mapState({
      currentUser: state => state.global.user,
      userA: state => state.v2.myinbox.messageThreadDetail.userA,
      userB: state => state.v2.myinbox.messageThreadDetail.userB,
    }),
    partnerChatUser() {
      if (this.userA) {
        if (this.userA.id === this.currentUser.id) {
          return this.userB;
        } else {
          return this.userA;
        }
      }
      return null;
    },
    loading() {
      return this.$store.state.v2.myinbox.messageThreadDetail.fetchingMessages;
    },
    breadcrumbs() {
      if (!this.partnerChatUser) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('home.sidebarMenu.inbox'),
          exact: true,
          to: '/inbox',
        },
        {
          text: this.partnerChatUser.fullName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {
    await this.$store.dispatch('v2/myinbox/messageThreadDetail/getThreadMessages');
  },
  async fetch({ store, params, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    await store.commit('v2/myinbox/SET_OPEN_DETAIL', true);
    await store.commit('v2/myinbox/messageThreadDetail/SET_MESSAGE_THREAD_UUID', params.uuid);
  },
};
</script>
