<template>
  <div class="chat--box-wrapper">
    <div class="chat--box-top">
      <div class="chat--box-header" v-if="partnerChatUser">
        <img :src="partnerChatUser.photoProfile" />
        <div class="chat--box-name">{{ partnerChatUser.fullName }}</div>
      </div>
      <listing-info class="chat--box-listing-info" :listing="listing" v-if="listing" />
      <message-item
        v-for="(message, index) in messages"
        :message="message"
        :key="`message-${index}`"
      />
    </div>
    <type-box v-if="!noTypeBox" />
  </div>
</template>

<script>
import ListingInfo from '@/components/utils/listing-info';
import MessageItem from '@/components/inbox/partials/message-item';
import TypeBox from '@/components/inbox/partials/type-box';
import { mapState } from 'vuex';

export default {
  components: {
    ListingInfo,
    MessageItem,
    TypeBox,
  },
  props: ['messages', 'userA', 'userB', 'listing', 'noTypeBox'],
  computed: {
    ...mapState({
      currentUser: state => state.global.user,
    }),
    partnerChatUser() {
      if (this.userA) {
        if (this.userA.id === this.currentUser.id) {
          return this.userB;
        } else {
          return this.userA;
        }
      }
      return null;
    },
  },
};
</script>
